<template>
    <div>
        <GenericCrud
            route-name="SettingsVendor"
            route-action="list"
            :navbar-title="$t('settings.vendor.buttonLabel')"
            :navbar-back-route-without-history="{ name: 'Settings' }"
            :main-list-filter-label="$t('settings.vendor.filterLabel')"
            main-fist-scroll-to-selected
            :storeData="{
                mainListItems: {
                    action: 'vendor/fetch',
                    payload: { force: true },
                    options: null,
                    itemText: 'name'
                },
                mainItemById: {
                    action: 'vendor/fetchById',
                    payload: null,
                    options: null,
                },
                edit: {
                    action: 'vendor/edit',
                    payload: null,
                    options: null,
                    refetch: true,
                    hideButton: true
                },
                add: {
                    action: 'vendor/create',
                    payload: null,
                    options: null,
                },
                delete: {
                    action: 'vendor/delete',
                    payload: null,
                    options: null,
                    hideButton: true
                },
                findUsage: {
                    action: 'vendor/findUsage',
                    payload: null,
                    options: null,
                    hideButton: true
                },
                replace: {
                    action: 'vendor/replaceItemInRecord',
                    options: null,
                    hideButton: true
                },
            }"
            :newItemModelFields="{
                name: null,
                typeOfRecipient: null,
                processingType: null,
                address: null,
                appropriateSafeguards: [],
                groupsOfPersons: [],
                personalData: [],
                personalDataCategory: [],
                dataTransferSecurity: false,
                dpaEnclosure: [],
                legality: null,
                nationalLegalObligation: null,
                nationalDataProtectionLaw: [],
                legitimateInterest: null,
                flexibilityClause: [],
                locale: currentLanguage,
                translations: defaultTranslations,
            }"
            :locale="{
                usageActionType: {
                    label: $t('settings.vendor.usageActionType.label'),
                    items: $t('settings.vendor.usageActionType.items')
                },
                replaceConfirmation: {
                    headline: $t('settings.vendor.replaceConfirmation.headline'),
                    text: $t('settings.vendor.replaceConfirmation.text'),
                    confirmationText: $t('settings.vendor.replaceConfirmation.confirmationText')
                }
            }"
        >
            <template v-slot:editorMask="x">
                <EditorMask
                    v-model="x.selectedMainItem"
                    :key="x.id"
                    :countryItems="countryItems"
                    :externalRecipientCategoryItems="externalRecipientCategoryItems"
                    :appropriateSafeguardItems="appropriateSafeguardItems"
                    :groupsOfPersonItems="groupsOfPersonItems"
                    :legalityItems="legalityItems"
                    :flexibilityClauseItems="flexibilityClauseItems"
                    :recipientNationalLegalObligationItems="recipientNationalLegalObligationItems"
                    :nationalDataProtectionLawItems="nationalDataProtectionLawItems"
                    :personalDataCategoryItems="personalDataCategoryItems"
                    :personalDataItems="personalDataItems"
                    :available-languages="availableLanguages"
                    :current-language="currentLanguage"
                    :isWaiting="x.isWaiting"
                    :isModified="x.isModified"
                    @modified="x.onSetModified"
                    @onSaveItem="x.onSaveItem"
                    @onDeleteItem="x.onDeleteItem"
                    @onFindUsage="x.onFindUsage"
                    @onCreateItem="x.onCreateItem"
                />
            </template>
        </GenericCrud>
    </div>
</template>

<script>
import GenericCrud from '@/components/generic/GenericCrud.vue';
import EditorMask from './EditorMask.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'SettingsVendorIndex',
    components: {
        GenericCrud,
        EditorMask,
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    computed: {
        ...mapGetters({
            availableLanguages: 'languageSettings/getAvailableLanguages',
            currentLanguage: 'languageSettings/getCurrentLanguage',
            countryItems: 'countries/getItems',
            externalRecipientCategoryItems: 'externalRecipientCategories/getItems',
            appropriateSafeguardItems: 'appropriateSafeguards/getItems',
            groupsOfPersonItems: 'groupsOfPersons/getItems',
            legalityItems: 'legality/getItems',
            flexibilityClauseItems: 'flexibilityClause/getItems',
            recipientNationalLegalObligationItems: 'recipientNationalLegalObligation/getItems',
            nationalDataProtectionLawItems: 'nationalDataProtectionLaw/getItems',
            personalDataCategoryItems: 'personalDataCategories/getItems',
            personalDataItems: 'personalData/getItems',
        }),
        defaultTranslations() {
            let translations = this.availableLanguages.map(x => {
                return { purpose: null, subcontractors: null, lcm: null, locale: x};
            });
            
            return translations.sort((a, b) => {
                return (b.locale === this.currentLanguage && a.locale !== this.currentLanguage) ? 1 : -1;
            });
        }
    },
    methods: {
        ...mapActions({
            fetchExternalRecipientCategoryItems: 'externalRecipientCategories/fetch',
            fetchCountryItems: 'countries/fetch',
            fetchAppropriateSafeguardItems: 'appropriateSafeguards/fetch',
            fetchGroupsOfPersonItems: 'groupsOfPersons/fetch',
            fetchLegalityItems: 'legality/fetch',
            fetchFlexibilityClauseItems: 'flexibilityClause/fetch',
            fetchRecipientNationalLegalObligationItems: 'recipientNationalLegalObligation/fetch',
            fetchNationalDataProtectionLawItems: 'nationalDataProtectionLaw/fetch',
            fetchPersonalDataCategoryItems: 'personalDataCategories/fetch',
            fetchPersonalDataItems: 'personalData/fetch',
        }),
        async fetchData() {
            this.$wait.start('pageLoader');
            await Promise.all([this.fetchCountryItems()]);
            await Promise.all([this.fetchExternalRecipientCategoryItems()]);
            await Promise.all([this.fetchAppropriateSafeguardItems()]);
            await Promise.all([this.fetchGroupsOfPersonItems()]);
            await Promise.all([this.fetchLegalityItems()]);
            await Promise.all([this.fetchFlexibilityClauseItems()]);
            await Promise.all([this.fetchRecipientNationalLegalObligationItems()]);
            await Promise.all([this.fetchNationalDataProtectionLawItems()]);
            await Promise.all([this.fetchPersonalDataCategoryItems()]);
            await Promise.all([this.fetchPersonalDataItems()]);
            this.$wait.end('pageLoader');
        },
    },
    created() {
        this.fetchData();
    },
};
</script>
