var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GenericCrud',{attrs:{"route-name":"SettingsVendor","route-action":"list","navbar-title":_vm.$t('settings.vendor.buttonLabel'),"navbar-back-route-without-history":{ name: 'Settings' },"main-list-filter-label":_vm.$t('settings.vendor.filterLabel'),"main-fist-scroll-to-selected":"","storeData":{
            mainListItems: {
                action: 'vendor/fetch',
                payload: { force: true },
                options: null,
                itemText: 'name'
            },
            mainItemById: {
                action: 'vendor/fetchById',
                payload: null,
                options: null,
            },
            edit: {
                action: 'vendor/edit',
                payload: null,
                options: null,
                refetch: true,
                hideButton: true
            },
            add: {
                action: 'vendor/create',
                payload: null,
                options: null,
            },
            delete: {
                action: 'vendor/delete',
                payload: null,
                options: null,
                hideButton: true
            },
            findUsage: {
                action: 'vendor/findUsage',
                payload: null,
                options: null,
                hideButton: true
            },
            replace: {
                action: 'vendor/replaceItemInRecord',
                options: null,
                hideButton: true
            },
        },"newItemModelFields":{
            name: null,
            typeOfRecipient: null,
            processingType: null,
            address: null,
            appropriateSafeguards: [],
            groupsOfPersons: [],
            personalData: [],
            personalDataCategory: [],
            dataTransferSecurity: false,
            dpaEnclosure: [],
            legality: null,
            nationalLegalObligation: null,
            nationalDataProtectionLaw: [],
            legitimateInterest: null,
            flexibilityClause: [],
            locale: _vm.currentLanguage,
            translations: _vm.defaultTranslations,
        },"locale":{
            usageActionType: {
                label: _vm.$t('settings.vendor.usageActionType.label'),
                items: _vm.$t('settings.vendor.usageActionType.items')
            },
            replaceConfirmation: {
                headline: _vm.$t('settings.vendor.replaceConfirmation.headline'),
                text: _vm.$t('settings.vendor.replaceConfirmation.text'),
                confirmationText: _vm.$t('settings.vendor.replaceConfirmation.confirmationText')
            }
        }},scopedSlots:_vm._u([{key:"editorMask",fn:function(x){return [_c('EditorMask',{key:x.id,attrs:{"countryItems":_vm.countryItems,"externalRecipientCategoryItems":_vm.externalRecipientCategoryItems,"appropriateSafeguardItems":_vm.appropriateSafeguardItems,"groupsOfPersonItems":_vm.groupsOfPersonItems,"legalityItems":_vm.legalityItems,"flexibilityClauseItems":_vm.flexibilityClauseItems,"recipientNationalLegalObligationItems":_vm.recipientNationalLegalObligationItems,"nationalDataProtectionLawItems":_vm.nationalDataProtectionLawItems,"personalDataCategoryItems":_vm.personalDataCategoryItems,"personalDataItems":_vm.personalDataItems,"available-languages":_vm.availableLanguages,"current-language":_vm.currentLanguage,"isWaiting":x.isWaiting,"isModified":x.isModified},on:{"modified":x.onSetModified,"onSaveItem":x.onSaveItem,"onDeleteItem":x.onDeleteItem,"onFindUsage":x.onFindUsage,"onCreateItem":x.onCreateItem},model:{value:(x.selectedMainItem),callback:function ($$v) {_vm.$set(x, "selectedMainItem", $$v)},expression:"x.selectedMainItem"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }