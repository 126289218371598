<template>
    <div class="settings">
        <Vendor />
    </div>
</template>

<script>
import Vendor from '@/components/settings/vendor/index.vue';

export default {
    name: 'SettingsVendor',
    components: {
        Vendor,
    },
};
</script>
